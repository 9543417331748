import {
  Button,
  IconInvoicing,
  IconPayment,
  IconPlus1,
  ThemeProvider,
  Typography,
} from "@herohealthsoftware/ui";
import React from "react";
import { translate } from "../../../lib/i18n";
import { Appointment, Invoice } from "../../../lib/types";
import * as Routes from "../../../routes";
import IconStripe from "./IconStripe";
import InvoiceListItem from "./InvoiceListItem";

type InvoicesEmbeddedListProps = {
  appointment: Appointment;
  invoices: Invoice[];
};

export default function InvoicesEmbeddedList(props: InvoicesEmbeddedListProps) {
  const newInvoicePath = Routes.new_partners_stripe_admins_invoice_path({
    appointment_id: props.appointment.id,
    back_path: window.location.pathname,
  });

  return (
    <ThemeProvider>
      <div className="flex flex-col border border-hero-blue-200 rounded-lg">
        <div
          className="flex flex-row gap-2 items-center justify-between
            bg-hero-blue-50 border-b pl-6 pr-4 py-3 rounded-t-lg last:rounded-b-lg"
        >
          <div className="flex items-center gap-2">
            <div className="h-6 w-6">
              <IconStripe />
            </div>

            <Typography size="xl" weight="bold" color="primary">
              <h4>{translate("partners.stripe.stripe")}</h4>
            </Typography>
          </div>

          <div className="flex items-center gap-2">
            <a
              href={newInvoicePath}
              className="rounded-lg hover:bg-hero-blue-100 p-2"
            >
              <div className="w-5 h-5 [&_path]:fill-hero-blue-700">
                <IconPlus1 />
              </div>
            </a>
          </div>
        </div>

        {props.invoices.length === 0 && (
          <div className="flex flex-col gap-2 items-center p-6 ">
            <div className="h-12 w-12 flex items-center justify-center rounded-lg bg-hero-blue-100">
              <div className="h-6 w-6 [&_path]:fill-hero-blue-700">
                <IconInvoicing />
              </div>
            </div>

            <Typography size="lg" color="primary" weight="bold">
              <h5>
                {translate("partners.stripe.thisAppointmentHasNoInvoices")}
              </h5>
            </Typography>

            <Typography size="base" color="secondary" className="text-center">
              <h5>
                {translate(
                  "partners.stripe.thisAppointmentHasNoInvoicesDescription"
                )}
              </h5>
            </Typography>

            <Button variant="primary" className="mt-4">
              <a
                href={newInvoicePath}
                className="flex flex-row items-center gap-2"
              >
                <div className="h-4 w-4">
                  <IconPlus1 />
                </div>

                {translate("partners.stripe.createInvoice")}
              </a>
            </Button>
          </div>
        )}

        {props.invoices.map((invoice) => (
          <InvoiceListItem
            key={invoice.id}
            invoice={invoice}
            handleInvoiceClick={() => {
              window.location.href = Routes.admin_appointment_path(
                invoice.appointment_id,
                { integration: "stripe", invoice_id: invoice.id }
              );
            }}
            action={
              invoice.status === "open" && (
                <Button size="sm" variant="white">
                  <a
                    href={Routes.new_partners_stripe_admins_invoice_payment_path(
                      invoice.id,
                      { back_path: window.location.pathname }
                    )}
                    className="flex flex-row items-center gap-1 text-hero-blue-700"
                  >
                    <div className="h-4 w-4">
                      <IconPayment />
                    </div>
                    {translate("partners.stripe.payNow")}
                  </a>
                </Button>
              )
            }
          />
        ))}
      </div>
    </ThemeProvider>
  );
}
