import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import DataTable from "./DataTable";
import { type CreateApiClientProps } from "@herohealthsoftware/api-sdk";

const appointmentsBoookedTable = ({
  ...props
}: CreateApiClientProps & { ehrEnabled: string }) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable {...props} />
    </QueryClientProvider>
  );
};

export default appointmentsBoookedTable;
