import React from "react";
import HeroHealthMessageBuilder from "@herohealthsoftware/message-builder-react";
import { Spinner, ThemeProvider } from "@herohealthsoftware/ui";

const MessageBuilderPage = ({
  env,
  apiKey,
  practiceGroupId,
  patientId,
  adminId,
  taskId,
  jwtToken,
}) => {
  return (
    <>
      {jwtToken ? (
        <HeroHealthMessageBuilder
          env={env === "production" ? "production" : "staging"}
          tenantId={practiceGroupId}
          patientId={patientId}
          adminId={adminId}
          taskId={taskId}
          jwtToken={jwtToken}
          telemetry
          loader={
            <ThemeProvider>
              <Spinner className="h-12 w-12" />
            </ThemeProvider>
          }
        />
      ) : (
        <HeroHealthMessageBuilder
          env={env === "production" ? "production" : "staging"}
          tenantId={practiceGroupId}
          patientId={patientId}
          adminId={adminId}
          taskId={taskId}
          apiKey={apiKey}
          telemetry
          loader={
            <ThemeProvider>
              <Spinner className="h-12 w-12" />
            </ThemeProvider>
          }
        />
      )}
    </>
  );
};

export default MessageBuilderPage;
