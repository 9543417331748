import { Badge } from "@herohealthsoftware/ui";
import React from "react";
import { translate } from "../../../lib/i18n";
import { Invoice } from "../../../lib/types";
import StatusBadge from "./StatusBadge";

type InvoiceStatusBadgesProps = {
  invoice: Invoice;
};

export default function InvoiceStatusBadges(props: InvoiceStatusBadgesProps) {
  return (
    <div className="flex gap-2 items-start">
      <StatusBadge resource="invoice" status={props.invoice.status} />

      {props.invoice.stripe_credit_notes_amount > 0 && (
        <Badge
          variant="custom"
          color="neutral"
          size="sm"
          className="font-semibold !py-1"
        >
          {translate("partners.stripe.creditNote")}
        </Badge>
      )}

      {props.invoice.stripe_amount_refunded > 0 && (
        <Badge
          variant="custom"
          color="neutral"
          size="sm"
          className="font-semibold !py-1"
        >
          {translate("partners.stripe.refund")}
        </Badge>
      )}
    </div>
  );
}
