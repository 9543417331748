import {
  PatientSearch as HeroPatientSearch,
  ThemeProvider,
  formatPatientDobStd,
  Typography,
  IconCloudDisconnected,
  IconSearchCircle,
  IconMissingUser,
  IconExclamation,
  formatDobToApi,
} from "@herohealthsoftware/ui";
import React, { useState, useMemo } from "react";
import {
  createApiClient,
  type PatientDto,
  type CreateApiClientProps,
} from "@herohealthsoftware/api-sdk";
import * as Routes from "../../routes";
import Hero from "../../lib/hero";
import { translate } from "../../lib/i18n";

type Patient = {
  id?: string;
  fullEmisId?: string;
  emisId?: string;
  dob: string | null;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  gender: PatientDto["sex"];
  nhsNumber?: string | null | undefined;
  consent?: boolean;
  lastSyncAt?: Date | null;
};

type PatientSearchProps = CreateApiClientProps & {
  ehrEnabled: boolean;
  isRoot?: boolean;
  onChange?: (patient: { value: Patient }) => void;
  emisEnabled: boolean;
};

const mapFromHeroApi = (patient: PatientDto): Patient => ({
  id: String(patient.id),
  dob: patient.dob ? formatPatientDobStd({ dob: new Date(patient.dob) }) : null,
  firstName: patient.first_name,
  lastName: patient.last_name,
  gender: patient.sex,
  nhsNumber: patient.nhs_number,
  consent: false,
  lastSyncAt: patient.last_sync_at ? new Date(patient.last_sync_at) : null,
});

const mapFromEmisApi = (patient: PatientDto, index: string): Patient => ({
  id: index,
  fullEmisId: patient.emis_id,
  emisId: patient.emis_id.split("-")[1],
  dob: patient.dob ? formatPatientDobStd({ dob: new Date(patient.dob) }) : null,
  firstName: patient.first_name,
  lastName: patient.last_name,
  gender: patient.sex,
});

const PatientSearch = ({
  isRoot = false,
  emisEnabled,
  ...props
}: PatientSearchProps) => {
  const [value, setValue] = useState("");
  const [dataSource, setDataSource] = useState(
    `${emisEnabled ? "emis" : "hero"}`
  );
  const heroFilters = [
    { label: "NHS Number", value: "nhs_number", placeholder: "e.g. 2949253326" },
    { label: "EMIS ID", value: "emis_id", placeholder: "e.g. 50003" },
    { label: "DOB", value: "dob", placeholder: "e.g. 23-11-1998" },
  ];
  const emisFilters = [
    { label: "EMIS ID", value: "emisId", placeholder: "e.g. 2949253326" },
  ];
  const [appliedFilter, setAppliedFilter] = useState<{
    label: string;
    value: string;
    placeholder?: string;
  } | null>(null);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [searchCount, setSearchCount] = useState(0);
  const Provider = isRoot ? ThemeProvider : React.Fragment;
  const apiClient = createApiClient(props);
  const [headerNode, setHeaderNode] = useState<React.ReactNode | undefined>(
    undefined
  );
  const [hideFilters, setHideFilters] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = async ({ value: patient }) => {
    if (dataSource === "hero") {
      window.location.href = Routes.patient_dashboard_path(patient.id);
    } else {
      setLoading(true);
      await Hero.fetch(
        Routes.api_partners_emis_search_index_path({
          emis_id: patient.fullEmisId,
        }),
        { method: "POST" }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response !== null) {
            window.location.href = Routes.patient_dashboard_path(response);
          } else {
            setHeaderNode(
              <div className="-herolib-px-3 -herolib-py-2.5 -herolib-flex -herolib-gap-2 -herolib-items-center -herolib-border-b border-">
                <div className="-herolib-min-w-5 -herolib-h-5">
                  <IconMissingUser />
                </div>
                <Typography size="sm" weight="normal">
                  <p>
                    {translate(
                      "admin.patientSearch.unableToCreatePatientInHero"
                    )}
                  </p>
                </Typography>
              </div>
            );
            setSearchCount(searchCount + 1);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const getHeroPatients = (value: string, filter: string | null) => {
    if (filter === "dob") {
      try {
        formatDobToApi(value);
      } catch (error) {
        setHeaderNode(
          <>
            <div className="-herolib-px-3 -herolib-py-2.5 -herolib-flex -herolib-gap-2 -herolib-items-center -herolib-border-b border-">
              <div className="-herolib-min-w-5 -herolib-h-5 [&_path]:-herolib-fill-red-700">
                <IconExclamation />
              </div>
              <Typography size="sm" weight="normal">
                <p>{translate("admin.patientSearch.invalidDateFormat")}</p>
              </Typography>
            </div>
          </>
        );
        return Promise.resolve([]);
      }
    }
    return apiClient.patients
      .listPatients({
        search: filter === "dob" ? formatDobToApi(value) : value,
        field: filter ?? undefined,
      })
      .then((item) =>
        item.data.map((patient) => ({
          value: mapFromHeroApi(patient),
          label: `${patient.last_name?.toUpperCase()}, ${patient.first_name}`,
        }))
      )
      .catch((error) => {
        console.log(error.message);
        if (error.message === "Failed to fetch") {
          setHeaderNode(
            <div className="-herolib-px-3 -herolib-py-2.5 -herolib-flex -herolib-gap-2 -herolib-items-center -herolib-border-b border-">
              <div className="-herolib-min-w-5 -herolib-h-5 [&_path]:-herolib-fill-red-700">
                <IconCloudDisconnected />
              </div>
              <Typography size="sm" weight="normal">
                <p>{translate("admin.patientSearch.errorFetchingPatientsFromHeroPublicApi")}</p>
              </Typography>
            </div>
          );
        } else {
          setHeaderNode(
            <>
              <div className="-herolib-px-3 -herolib-py-2.5 -herolib-flex -herolib-gap-2 -herolib-items-center -herolib-border-b border-">
                <div className="-herolib-min-w-5 -herolib-h-5">
                  <IconMissingUser />
                </div>
                <Typography size="sm" weight="normal">
                  <p>
                    {translate("admin.patientSearch.couldNotFindPatientInHero")}
                  </p>
                </Typography>
              </div>
            </>
          );
        }

        return [];
      });
  };

  const getEmisPatients = async (value: string, filter?: string | null) =>
    await Hero.fetch(
      Routes.api_partners_emis_search_index_path({
        search: encodeURIComponent(value.toLowerCase()),
        format: "js",
      })
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.length === 0) {
          setHeaderNode(
            <>
              <div className="-herolib-px-3 -herolib-py-2.5 -herolib-flex -herolib-gap-2 -herolib-items-center -herolib-border-b border-">
                <div className="-herolib-min-w-5 -herolib-h-5">
                  <IconMissingUser />
                </div>
                <Typography size="sm" weight="normal">
                  <p>
                    {translate(
                      "admin.patientSearch.couldNotFindPatientFromEmis"
                    )}
                  </p>
                </Typography>
              </div>
            </>
          );
        }
        return responseJson.map((patient, index) => ({
          value: mapFromEmisApi(patient, index),
          label: `${patient.last_name?.toUpperCase()}, ${patient.first_name}`,
        }));
      })
      .catch(() => {
        setHeaderNode(
          <>
            <div className="-herolib-px-3 -herolib-py-2.5 -herolib-flex -herolib-gap-2 -herolib-border-b border-">
              <div className="-herolib-min-w-5 -herolib-h-5 [&_path]:-herolib-fill-red-700">
                <IconCloudDisconnected />
              </div>
              <Typography size="sm" weight="normal">
                <p>{translate("admin.patientSearch.couldNotConnectToEmis")}</p>
              </Typography>
            </div>
            <div
              className="-herolib-px-3 -herolib-py-2.5 -herolib-flex -herolib-gap-2 hover:-herolib-bg-gray-50 -herolib-cursor-pointer"
              onClick={() => {
                setDataSource("hero");
                setTriggerSearch(true);
                setSearchCount(searchCount + 1);
                setHeaderNode(undefined);
              }}
            >
              <div className="-herolib-min-w-5 -herolib-h-5">
                <IconSearchCircle />
              </div>
              <Typography size="sm" weight="semibold">
                <p>{translate("admin.patientSearch.searchPatientsInHero")}</p>
              </Typography>
            </div>
          </>
        );
        setHideFilters(true);
      });

  const fetcher = useMemo(() => {
    return (
      value: string,
      filter: string | null,
      callback: (options: unknown[]) => void
    ) => {
      if (triggerSearch && value.length > 0) {
        if (dataSource === "hero") {
          getHeroPatients(value, filter).then((results) => callback(results));
        } else {
          getEmisPatients(value, filter).then((results) => callback(results));
        }
        setTriggerSearch(false);
      } else {
        new Promise(() => {
          callback([]);
        });
      }
    };
  }, [triggerSearch]);

  return (
    <Provider>
      <HeroPatientSearch
        triggerBasedSearch
        key={searchCount}
        blurInputOnSelect={false}
        inputValue={value}
        openMenuOnFocus
        onInputChange={(value, action) => {
          if (action.action === "input-change") {
            setValue(value);
          }
          setHeaderNode(undefined);
          setHideFilters(false);
        }}
        onChange={onChange}
        filters={
          hideFilters
            ? undefined
            : dataSource === "hero"
            ? heroFilters
            : emisFilters
        }
        dataSource={dataSource}
        onDataSourceChange={emisEnabled ? setDataSource : undefined}
        onTriggerSearchChange={setTriggerSearch}
        fetcher={fetcher as never}
        defaultOptions={true}
        defaultMenuIsOpen={searchCount > 0}
        autoFocus={searchCount > 0}
        searchCount={searchCount}
        onSearchCountChange={setSearchCount}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setSearchCount(searchCount + 1);
            setTriggerSearch(true);
            setHeaderNode(undefined);
            e.preventDefault();
          }
        }}
        className="w-[504px]"
        onAdd={
          dataSource === "hero"
            ? () => {
                props.ehrEnabled
                  ? Hero.remote({
                      url: Routes.create_patient_sidebar_path({
                        format: "js",
                      }),
                      type: "GET",
                    })
                  : Hero.remote({
                      url: Routes.existing_patient_search_admin_patients_path({
                        format: "js",
                      }),
                      type: "GET",
                    });
              }
            : undefined
        }
        addText={translate("common.addPatient")}
        filtersHeader={
          dataSource === "emis" ? "Or retrieve a patient by" : undefined
        }
        filtersHeaderPosition={dataSource === "emis" ? "left" : "top"}
        onAppliedFilterChange={(value) => {
          setAppliedFilter(value);
          setValue("");
        }}
        appliedFilter={appliedFilter}
        headerNode={headerNode}
        loading={loading}
        onValueChange={setValue}
        isClearable={false}
      />
    </Provider>
  );
};

export default PatientSearch;
