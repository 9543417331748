import React, { useState } from "react";
import Checkbox from "../../../atoms/Checkbox";
import { TextInput } from "@herohealthsoftware/ui";
import { translate } from "../../../../lib/i18n";

type InvoiceBillingDetails = {
  insurer: string;
  authentication_code: string;
  membership_number: string;
};

export type InsuranceDetailsInputProps = {
  insuranceDetails: boolean;
  insuranceDetailsError?: string;
  setInsuranceDetails: React.Dispatch<React.SetStateAction<boolean>>;
  handleInsuranceDetailsChange: (
    fieldName: string,
    value: string | boolean
  ) => void;
  invoice: {
    id: number;
    insurer: string;
    status: string;
    authentication_code: string;
    membership_number: string;
  };
  invoiceBillingDetailsDefaultValues: InvoiceBillingDetails;
};

const InsuranceDetailsInput: React.FC<InsuranceDetailsInputProps> = ({
  insuranceDetails,
  insuranceDetailsError,
  handleInsuranceDetailsChange,
  invoice,
  setInsuranceDetails,
  invoiceBillingDetailsDefaultValues,
}) => {
  const editable = ["draft", "open", "paid"].includes(invoice.status);

  return (
    <>
      <div className="mb-6">
        <Checkbox
          id="insurer_details"
          name="insurer_details_checkbox"
          value="6"
          label={translate("partners.stripe.addInsuranceDetails")}
          handleChange={(e) => {
            setInsuranceDetails(e.target.checked);
          }}
          checked={insuranceDetails}
        />
        {insuranceDetails ? (
          <div className="flex flex-col gap-1">
            <div className="flex gap-4 ml-6 mt-5">
              <TextInput
                className="form-control text optional mt-2 w-52"
                name="partners_stripe_invoice[insurer]"
                id="partners_stripe_invoice_insurer"
                label="Insurer"
                defaultValue={
                  invoice.id || invoice.insurer
                    ? invoice.insurer
                    : invoiceBillingDetailsDefaultValues.insurer
                }
                onChange={(e) =>
                  handleInsuranceDetailsChange("insurer", e.target.value)
                }
                disabled={!editable}
              />
              <TextInput
                className="form-control text optional mt-2 w-54"
                name="partners_stripe_invoice[authentication_code]"
                id="partners_stripe_invoice_authentication_code"
                label="Authentication code"
                defaultValue={
                  invoice.id || invoice.authentication_code
                    ? invoice.authentication_code
                    : invoiceBillingDetailsDefaultValues.authentication_code
                }
                onChange={(e) =>
                  handleInsuranceDetailsChange(
                    "authentication_code",
                    e.target.value
                  )
                }
                disabled={!editable}
              />
              <TextInput
                className="form-control text optional mt-2 w-52"
                name="partners_stripe_invoice[membership_number]"
                id="partners_stripe_invoice_membership_number"
                label="Membership number"
                defaultValue={
                  invoice.id || invoice.membership_number
                    ? invoice.membership_number
                    : invoiceBillingDetailsDefaultValues.membership_number
                }
                onChange={(e) =>
                  handleInsuranceDetailsChange(
                    "membership_number",
                    e.target.value
                  )
                }
                disabled={!editable}
              />
            </div>
            {insuranceDetailsError && (
              <div className="text-red-600 text-base ml-6">
                {insuranceDetailsError}
              </div>
            )}
          </div>
        ) : (
          <>
            <input
              value=""
              type="hidden"
              name="partners_stripe_invoice[insurer]"
            />
            <input
              value=""
              type="hidden"
              name="partners_stripe_invoice[authentication_code]"
            />
            <input
              value=""
              type="hidden"
              name="partners_stripe_invoice[membership_number]"
            />
          </>
        )}
      </div>
    </>
  );
};
export default InsuranceDetailsInput;
