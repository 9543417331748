import type { ColumnFiltersState, SortingState } from "@tanstack/react-table";
import { type HeroHealthAPIClient } from "@herohealthsoftware/api-sdk";

export async function fetchData({
  pagination,
  columnFilters,
  sorting,
  apiClient,
}: {
  pagination?: { pageIndex: number; pageSize: number };
  columnFilters: ColumnFiltersState;
  sorting: SortingState;
  apiClient: HeroHealthAPIClient;
}) {
  const data = await apiClient.booking
    .listAppointments({
      pageIndex: pagination ? pagination.pageIndex.toString() : undefined,
      pageSize: pagination ? pagination.pageSize.toString() : undefined,
      orderBy:
        Array.isArray(sorting) && sorting.length > 0
          ? (sorting[0].id as
              | "patient_first_name"
              | "patient_last_name"
              | "patient_full_name"
              | "practitioner_first_name"
              | "practitioner_last_name"
              | "practitioner_full_name"
              | "appointment_template_name"
              | "appointment_source"
              | "location_name"
              | "confirmed_at"
              | "start_time")
          : undefined,
      orderDescending:
        Array.isArray(sorting) && sorting.length > 0
          ? sorting[0].desc
          : undefined,
      patientFullName:
        Array.isArray(columnFilters) &&
        columnFilters.length > 0 &&
        columnFilters.find((e) => e.id === "patient")
          ? (columnFilters.find((e) => e.id === "patient").value as string)
          : undefined,
      practitionerFullName:
        Array.isArray(columnFilters) &&
        columnFilters.length > 0 &&
        columnFilters.find((e) => e.id === "practitioner")
          ? (columnFilters.find((e) => e.id === "practitioner").value as string)
          : undefined,
      appointmentTemplateName:
        Array.isArray(columnFilters) &&
        columnFilters.length > 0 &&
        columnFilters.find((e) => e.id === "appointment_template_name")
          ? (columnFilters.find((e) => e.id === "appointment_template_name")
              .value as string)
          : undefined,
      locationName:
        Array.isArray(columnFilters) &&
        columnFilters.length > 0 &&
        columnFilters.find((e) => e.id === "location_name")
          ? (columnFilters.find((e) => e.id === "location_name")
              .value as string)
          : undefined,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return {
    rows: data,
    pageCount: Math.ceil(data[0].count / data.length),
    rowCount: data[0].count,
  };
}
