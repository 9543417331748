import React, { useState } from "react";
import {
  ThemeProvider,
  DataTable as HeroDataTable,
  SortingState,
  ColumnFiltersState,
} from "@herohealthsoftware/ui";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { columns } from "./Columns";
import { fetchData } from "./fetchData";
import * as Routes from "../../../../routes";
import {
  createApiClient,
  type CreateApiClientProps,
} from "@herohealthsoftware/api-sdk";

const convertToCSV = (objArray) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line !== "") line += ",";

      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
};

const downloadCSV = ({ data, fileName }) => {
  const csvData = new Blob([convertToCSV(data)], { type: "text/csv" });
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement("a");
  link.href = csvURL;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const DataTable = ({
  ehrEnabled,
  ...props
}: CreateApiClientProps & { ehrEnabled: string }) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "confirmed_at", desc: true },
  ]);
  const [downloading, setDownloading] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState("");
  const apiClient = createApiClient(props);

  const dataQuery = useQuery({
    queryKey: ["data", pagination, columnFilters, sorting],
    queryFn: () => fetchData({ pagination, columnFilters, sorting, apiClient }),
    placeholderData: keepPreviousData,
  });

  const onRowClick = (row: any) => {
    window.location.href = Routes.admin_appointment_path({
      id: row.original.id,
    });
  };

  const handleDownloadButtonClick = () => {
    setDownloading(true);

    fetchData({ columnFilters, sorting, apiClient })
      .then((response) => {
        const formattedData = [
          {
            id: "id",
            patient_title: "patient_title",
            patient_first_name: "patient_first_name",
            patient_last_name: "patient_last_name",
            practitioner_title: "practitioner_title",
            practitioner_first_name: "practitioner_first_name",
            practitioner_last_name: "practitioner_last_name",
            location_id: "location_id",
            location_name: "location_name",
            start_time: "start_time",
            can_be_cancelled: "can_be_cancelled",
            appointment_template_name: "appointment_template_name",
            cancelled_at: "cancelled_at",
            confirmed_at: "confirmed_at",
            appointment_source: "appointment_source",
          },
        ].concat(
          response.rows.map((row) => ({
            id: row.id,
            patient_title: row.patient.title,
            patient_first_name: row.patient.first_name,
            patient_last_name: row.patient.last_name,
            practitioner_title: row.practitioner.title,
            practitioner_first_name: row.practitioner.first_name,
            practitioner_last_name: row.practitioner.last_name,
            location_id: row.location_id,
            location_name: row.location_name,
            start_time: row.start_time,
            can_be_cancelled: row.can_be_cancelled,
            appointment_template_name: row.appointment_template_name,
            cancelled_at: row.cancelled_at,
            confirmed_at: row.confirmed_at,
            appointment_source: row.appointment_source,
          }))
        );
        downloadCSV({ data: formattedData, fileName: "appointments" });
      })
      .then(() => {
        setDownloading(false);
        setDownloadStatus("Download complete!");
        setTimeout(() => {
          setDownloadStatus("");
        }, 2000);
      })
      .catch((error) => {
        alert(
          `Error downloading CSV, ${error}. If this persists, please contact support.`
        );
        setDownloading(false);
      });
  };

  return (
    <ThemeProvider>
      <HeroDataTable
        columns={columns}
        data={
          dataQuery.data?.rows.map((row) => ({
            ...row,
            ehrEnabled: ehrEnabled,
          })) || []
        }
        rowCount={dataQuery.data?.rowCount}
        loading={dataQuery.isFetching}
        manualPagination
        manualFiltering
        pagination={pagination}
        onPaginationChange={setPagination}
        columnFilters={columnFilters}
        onColumnFilterChange={setColumnFilters}
        sorting={sorting}
        onSortingChange={setSorting}
        onRowClick={onRowClick}
        onDownloadButtonClick={handleDownloadButtonClick}
        downloadButtonLoading={downloading}
        downloadButtonLabel={downloadStatus}
      />
    </ThemeProvider>
  );
};

export default DataTable;
